<template>
  <div class="preview-body">
    <div class="preview-placeholder">
      <img v-if="logo"
           :src="logo"
           class="preview-placeholder-logo" />
      <span class="preview-placeholder-text">
        {{ placeholder }}
      </span>
    </div>
    <div class="preview-content">
      <div class="preview-content-wrap">
        <PreviewBets :bets="ticket.bets"
                     :render-type="renderType"
                     :is-system="false" />
        <PreviewFooter :bonus="null"
                       :bonus-percentage="null"
                       :combinations="null"
                       :payment="payment"
                       :stake="stake"
                       :min-winning="minWinning"
                       :max-winning="maxWinning"
                       :max-winning-label="maxWinningLabel"
                       :payin-tax="payinTax"
                       :total-odds="totalOdds" />
      </div>
    </div>
  </div>
</template>

<script>
import PreviewBets from './PreviewBets';
import PreviewFooter from './PreviewFooter';
import config from '../../config';

export default {
  name: 'PreviewBody',
  components: {
    PreviewBets,
    PreviewFooter,
  },
  props: {
    ticket: {
      type: Object,
      default() {
        return {};
      },
    },
    renderType: {
      type: String,
    },
  },
  data() {
    return {
      logo: config.logo,
      placeholder: config.placeholder,
    };
  },
  computed: {
    bonus() {
      return this.ticket?.bonus || null;
    },
    bonusPercentage() {
      return this.ticket.bonusPercentage || null;
    },
    payinTax() {
      return this.ticket.paymentTax || 0.00;
    },
    payment() {
      return this.ticket.payment;
    },
    stake() {
      return this.ticket.stake;
    },
    minWinning() {
      return this.ticket.minWinning || 0.00;
    },
    maxWinning() {
      return this.ticket.maxWinning || 0.00;
    },
    maxWinningLabel() {
      return config.maxPossibleWinLabel;
    },
    totalOdds() {
      return null;
    },
  },
};
</script>

<style lang="scss">
.ticket-preview {
  &.sidebar .preview-placeholder {
    right: 25%;
  }

  .preview-body {
    background-color: #242424;
    height: 100%;
    padding-top: 50px;
  }

  .preview-content {
    position: absolute;
    left: 0;
    top: 50px;
    right: 0;
    bottom: 0;
  }

  .preview-content-wrap {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .preview-placeholder {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    color: #fff;
    font-size: 76px;
    line-height: 76px;
    transform: translate(0, -50%);
    opacity: 0.1;
    text-align: center;
  }

  .preview-placeholder-logo {
    margin-right: 0.15em;
    margin-top: -0.15em;
    font-size: 76px;
    height: 35px;
    width: 85px;
    vertical-align: middle;
  }
}
</style>
